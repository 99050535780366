<form id="modalEventForm" [formGroup]="formGroup" *ngIf="!deviceService.isMobile" (keydown.enter)="$event.preventDefault()">
  <div class="main-form-wrapper" [class.editable]="isEdited" [class.invalid]="formGroup.invalid">
    <mat-progress-bar *ngIf="!event && !isEventBeingFetched" mode="indeterminate"></mat-progress-bar>
    <div class="title-row">
      <div class="title-text-row" *ngIf="isEdited && isNewItem">
        <h1 >{{ 'events.action.create' | translate }}</h1>
        <div class="pointer customise-event-form" *ngIf="isUserAdmin" [innerHTML]="'create.entry.sub.text' | translate:{adminBaseUrl: adminBaseUrl}"></div>
      </div>
      <h1 *ngIf="isEdited && !isNewItem">{{ 'events.action.edit' | translate }}</h1>
      <h1 *ngIf="!isEdited && !event">{{ 'events.action.view' | translate }}</h1>
      <div *ngIf="!isEdited && event" class="top-actions">
        <div class="pull-left">
          <a (click)="sendMail()" *ngIf="!isNewItem">
            <ion-button class="option-button ml-2" fill="outline" color="primary">
              <ion-icon name="custom-email"></ion-icon>
              <span>{{ 'app.btn.share' | translate }}</span>
            </ion-button>
          </a>
          <ion-button *ngIf="!isNewItem && userRightsService.hasRight(userRightsService.USER_RIGHTS.site.event.create)"
            class="option-button ml-2" (click)="duplicate('webapp-event-copy')" fill="outline" color="primary">
            <ion-icon name="custom-copy"></ion-icon>
            <span>{{ 'btn.duplicate' | translate }}</span>
          </ion-button>
          <ion-button class="option-button ml-2"
            (click)="openPDFReportModalForSingleEvent()" fill="outline" color="primary"
            [disabled]="event.status.match(EventStatus.DRAFT) || !isOnline">
            <span class="button-inner">
              <i class="far fa-file-pdf fa-lg"></i>
              {{ 'events.export.pdf.events' | translate }}
            </span>
          </ion-button>
        </div>
        <div [class]="event.status.match(EventStatus.APPROVED)?'approved-banner':'main-form-buttons'">
          <button *ngIf="canEdit && !isNewItem && !event.status.match(EventStatus.APPROVED)" class="secondary-action-button button-right" [disabled]="isEdited"
            (click)="onEditClick()" color="primary">
            <span>{{ 'btn.edit' | translate }}</span>
          </button>
          <div *ngIf="event.status.match(EventStatus.APPROVED)">
            <span class="pull-left">
              <i class="fas fa-check"></i> &nbsp;&nbsp;
              <b>{{'events.detail.panel_history.approved_by' | translate}}:</b>&nbsp;
              {{event.updatedBy.name}}
            </span>&nbsp;
            <span class="pull-right">
              <b>{{'payment.bill.date' | translate}}:</b>&nbsp;
              {{event.updatedOn | date:'dd/MM/yy'}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isCustomFormUpdateRequired" class="custom-form-update"  (click)="onUpdateApplication()">
      <p class="update-title">{{ getCustomFormUpdateTitle() | translate }}</p>
      <p>{{ getCustomFormUpdateMessage() | translate }}</p>
    </div>
    <section id="form-container">
      <!-- START MANDATORY INFORMATIONS SECTION -->
      <section id="mandatory-information">
        <header id="mandatory-header">
          {{'events.section_title.mandatory_information' | translate }}
        </header>

        <div id="mandatory-details">
          <!-- TITLE -->
          <div id="title-label">
            <span class="label">{{ 'events.edit.field.title' | translate }}</span>
          </div>
          <div id="title-content">
            <span class="inline-text mr-2" *ngIf="!isEdited">{{ event?.title }}</span>
            <span class="label" *ngIf="!isEdited">{{ 'tasks.list.created_by' | translate }}
              {{event?.createdBy.id ? event?.createdBy.name : 'events.detail.panel_creation.unkown_user' | translate }}</span>
            <span class="validation-error">
              <span class="form-input" *ngIf="isEdited">
                <input id="title" required formControlName="title" type="text"
                  placeholder="{{'events.form.title.placeholder' | translate }}">
              </span>
              {{ getControlErrors('title') }}
            </span>
          </div>

          <!-- DATES -->
          <div id="dates-start-label">
            <span class="label vertically-align">{{ 'tasks.edit.field.start' | translate }}</span>
          </div>
          <div id="dates-content">
            <app-datetime-input #startDate (click)="startDate.openDatetimePicker()" [readonly]="!isEdited"
              [type]="'startDatetime'" placeholder="{{'mobile-placeholder-startdate' | translate}}"
              formControlName="startDatetime" class="dates">
            </app-datetime-input>

            <span class="label left-spacer">{{ 'tasks.edit.field.end' | translate }}</span>
            <app-datetime-input #endDate (click)="endDate.openDatetimePicker()"
              [canClearOnlyEndDatetime]="canClearEndDatetime" [readonly]="!isEdited" [type]="'endDatetime'"
              placeholder="{{'mobile-placeholder-enddate' | translate}}" formControlName="endDatetime" class="dates">
            </app-datetime-input>
          </div>

          <!-- DURATION -->
          <div id="duration-container">
            <div class="form-input-block" *ngIf="isDurationDisplayed()">
              {{ 'events.edit.field.duration' | translate }} {{ 'colon.symbol' | translate }}
              {{ formGroup.value.startDatetime | hourDiffBetweenTwoDates : formGroup.value.endDatetime }}
            </div>
          </div>
        </div>

        <div id="mandatory-weather">
          <!-- WEATHER  -->
          <div class="form-element really-high-field" [ngClass]="getValidationStyles('weather')">
            <label class="form-element-label" for="map-search" required>{{ 'weather_location' | translate }} <span
                *ngIf="isEdited">{{ 'wildcard.symbol' | translate }}</span></label>
                <div *ngIf="isWeatherDataLoading" class="spinner-border spinner-border-sm ml-1"></div>
            <div class="form-input-block form-input-block-multiline">
              <!-- weather info -->
              <div id="weather-body" class="weather-message">
                <ng-container *ngIf="event?.weather && weatherCodeDetails && isPreviewingWeather && !event.weather.ignored">
                  <img width="64" height="64" [src]="weatherCodeDetails.icon_path" />
                  <div id="weather-details">
                    <div class="temperature">
                      <p>{{event.weather.temperature | weatherTemperaturePipe | async}}</p>
                    </div>
                    <div class="humidity-wind">
                      <label class="wind">
                        <ion-icon color="medium" mode="md" name="wind"></ion-icon>
                      </label>
                      <div class="wind">
                        <p>{{event.weather.windSpeed | weatherWindspeedPipe | async}}</p>
                      </div>
                      <label class="humidity">
                        <ion-icon color="medium" mode="ios" name="humidity"></ion-icon>
                      </label>
                      <div class="humidity">
                        <p>{{ event.weather.humidity }} {{'app.weather.humidity' | translate }}</p>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isOnline && !weatherCodeDetails && !weatherApiInvalidResponse && !event?.weather?.ignored">
                  <img width="64" height="64" src="/assets/img/weather/weather_pending.svg" />
                  <p>{{ 'events.edit.weather.date_required' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="isOnline && !weatherCodeDetails && weatherApiInvalidResponse && !event?.weather?.ignored">
                  <img width="64" height="64" src="/assets/img/weather/weather_pending.svg" />
                  <p>{{ 'events.edit.weather.error' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="(isOnline && event?.weather === null) && !event?.weather?.ignored">
                  <img width="64" height="64" src="/assets/img/weather/weather_not_filled.svg" />
                  <p>{{ 'events.edit.weather.empty' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="event?.weather?.ignored">
                  <img width="64" height="64" src="/assets/img/weather/weather_ignored.svg" />
                  <p>
                    {{ 'sites.weather.ignored' | translate }}
                  </p>
                </ng-container>
              </div>

              <!-- weather map -->
              <div class="weather-select form-input-wrapper edit-only-field really-high-field">
                <div class="weather-select-container">
                  <app-select-weather *ngIf="canEdit" [defaultLat]="event?.weather?.latitude" [defaultLng]="event?.weather?.longitude"
                    [readOnly]="!isEdited" [desktopRenewWeather]="resetWeatherEmitter.asObservable()"
                    (weatherActiveEmitter)="onWeatherActiveEvent($event)"
                    (weatherChangeEmitter)="onCoordinatesChanged($event)"
                    (mapViewStatus)="onMapViewStatusChange($event)">
                  </app-select-weather>
                </div>
                <span class="validation-error">
                  {{ getControlErrors('weather') }}
                </span>
              </div>
            </div>
          </div>
          <div id="button-div">
            <button *ngIf="weatherApiInvalidResponse" class="btn-refresh-weather ml-2" (click)="previewWeather()" color="secondary">
              <em class="fas fa-redo"></em>
              <span>{{ 'label.refresh' | translate }}</span>
            </button>
          </div>
        </div>
      </section>
      <!-- END MANDATORY INFORMATION SECTION -->

      <!-- START CUSTOM FORM LAYOUT -->
      <div *ngIf="currentCustomEventForm && currentCustomEventForm.formLayout.length > 0" class="custom-form" [ngClass]="{'map-visible': isGoogleMapOpened}">
        <!-- START CUSTOM FORM SECTION -->
        <div *ngFor="let section of currentCustomEventForm.formLayout" class="custom-form-section"
          [class.hide-fields]="!isEdited && isSectionEmpty(section)">
          <!-- START SECTION TITLE -->
          <header>
            {{section.sectionTitle}}
          </header>
          <!-- END SECTION TITLE -->
          <div *ngFor="let field of currentCustomEventForm.customFields">
            <div *ngFor="let fieldId of section.sectionFields">
              <div *ngIf="field.id === fieldId" [class.hide-fields]="!isEdited && isFieldEmpty(field.id)">
                <!-- START LEGACY FIELD -->
                <div *ngIf="(field.fieldType.value === legacyFieldStringValue) && field.legacyFieldValue" class="custom-form-field"
                  [ngClass]="{'d-block': isFieldInAGrid(field), 'align-items-center': isFieldCenterAligned(field)}">
                  <!-- START LEGACY FIELD TITLE -->
                  <div class="custom-form-field-title">
                    {{field.title}}
                  </div>
                  <!-- END LEGACY FIELD TITLE -->
                  <!-- START CONTRACTOR LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.contractor">
                    <app-event-contractor [customField]="field" [parentFormGroup]="formGroup" [contractors]="contractors" [isEdited]="isEdited" [contractorsLoaded]="contractorsLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-contractor>
                  </div>
                  <!-- END CONTRACTOR LEGACY FIELD -->

                  <!-- START LOCATION LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.location">
                    <app-event-location [customField]="field" [parentFormGroup]="formGroup" [locations]="locations" [isEdited]="isEdited" [locationsLoaded]="locationsLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-location>
                  </div>
                  <!-- END LOCATION LEGACY FIELD -->

                  <!-- START PHOTOS LEGACY FIELD -->
                  <div *ngIf="field.legacyFieldValue.value === legacyFieldType.photos">
                    <app-event-photos #eventPhotosComponent [customField]="field" [parentFormGroup]="formGroup" [isEdited]="isEdited" [isViewReady]="isViewReady" [event]="event" [eventId]="itemId" [deviceIsMobile]="false"></app-event-photos>
                  </div>
                  <!-- END PHOTOS LEGACY FIELD -->

                  <!-- START TAG LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.tags">
                    <app-event-tag [customField]="field" [parentFormGroup]="formGroup" [tags]="tags" [isEdited]="isEdited" [tagsLoaded]="tagsLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-tag>
                  </div>
                  <!-- END TAG LEGACY FIELD -->

                  <!-- START PEOPLE LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.people">
                    <app-event-people [customField]="field" [parentFormGroup]="formGroup" [labours]="labours" [isEdited]="isEdited" [laboursLoaded]="laboursLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-people>
                  </div>
                  <!-- END PEOPLE LEGACY FIELD -->

                  <!-- START EQUIPMENT LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.equipment">
                    <app-event-equipment [customField]="field" [parentFormGroup]="formGroup" [plants]="plants" [isEdited]="isEdited" [plantsLoaded]="plantsLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-equipment>
                  </div>
                  <!-- END EQUIPMENT LEGACY FIELD -->

                  <!-- START MATERIAL LEGACY FIELD -->
                  <div class="custom-form-field-content" *ngIf="field.legacyFieldValue.value === legacyFieldType.materials">
                    <app-event-material [customField]="field" [parentFormGroup]="formGroup" [materials]="materials" [isEdited]="isEdited" [materialsLoaded]="materialsLoaded"
                      [isViewReady]="isViewReady">
                    </app-event-material>
                  </div>
                  <!-- END MATERIAL LEGACY FIELD -->

                  <!-- START NOTES LEGACY FIELD -->
                  <div *ngIf="field.legacyFieldValue.value === legacyFieldType.notes">
                    <app-event-notes [customField]="field" [parentFormGroup]="formGroup" [isEdited]="isEdited" [event]="event"
                      [isViewReady]="isViewReady">
                    </app-event-notes>
                  </div>
                  <!-- END CONTRACTOR LEGACY FIELD -->
                </div>
                <!-- END LEGACY FIELD -->

                <!-- START CUSTOM FIELD -->
                <div class="custom-form-field-content" *ngIf="field.fieldType.value !== legacyFieldStringValue">
                  <app-event-custom-field [customField]="field" [parentFormGroup]="formGroup" [isEdited]="isEdited" [event]="event">
                  </app-event-custom-field>
                </div>
                <!-- END CUSTOM FIELD -->
              </div>
            </div>
          </div>
        </div>
        <!-- END CUSTOM FORM SECTION -->
        <div class="no-form-msg" *ngIf="isCustomEventFormNotAvailable">
          {{'custom_event.no_form_found.error_message' | translate }}
        </div>
      </div>
      <!-- END CUSTOM FORM LAYOUT -->

      <!-- START APPROVE/REJECT COMMENT SECTION -->
      <section id="approvalCommentForEvent" *ngIf="!isNewItem && event && (event.status === EventStatus.REJECTED || event.status === EventStatus.APPROVED)">
        <header (click)="isCollapsedApprovalComment = !isCollapsedApprovalComment" [attr.aria-expanded]="!isCollapsedApprovalComment"
          aria-controls="collapseExample">
          {{'events.section_title.comments' | translate }}
          <em class="pull-right fas" [ngClass]="isCollapsedApprovalComment ? 'fa-chevron-down' : 'fa-chevron-up'"></em>
        </header>
        <section id="comments-section" [ngbCollapse]="isCollapsedApprovalComment">
          <b> {{event.updatedOn | date:'dd/MM/yy'}} @ {{event.updatedOn | date:'HH:mm'}} -
              {{ event.status.match(EventStatus.APPROVED) ? ('events.detail.panel_history.approved_by' | translate) : event.status.match(EventStatus.REJECTED) ? ('events.detail.panel_history.rejected_by' | translate) : '' }}
              {{event.updatedBy.name}}
          </b>
          <!-- START COMMENT -->
          <div class="main-form-row main-form-bottom form-element">
            <ng-container *ngIf="event">
              <textarea appAutoResizeInput="height" class="input-spe bigger-textarea notes-textarea"
                disabled>{{ event.approvalComment }}</textarea>
            </ng-container>
          </div>
          <!-- END COMMENT -->
        </section>
      </section>
      <!-- END APPROVE/REJECT COMMENT SECTION -->

    </section>
  </div>

  <!-- START ACTIONS -->
  <div class="main-form-buttons" *ngIf="event && !isCustomEventFormNotAvailable">
    <ul class="btn-list" *ngIf="isEdited">
      <li>
        <app-cancel-button (click)="cancel()"></app-cancel-button>
      </li>
      <li>
        <div [matTooltip]="getFormAndControlsErrors()" matTooltipClass="mat-tooltip-wide-and-multiline"
          matTooltipPosition="above">
          <app-draft-button (click)='beforeSave(EventStatus.DRAFT)' [disabled]="!formGroup.valid"></app-draft-button>
        </div>
      </li>
      <li>
        <div [matTooltip]="getFormAndControlsErrors()" matTooltipClass="mat-tooltip-wide-and-multiline"
          matTooltipPosition="above">
          <app-submit-button (click)='beforeSave(EventStatus.SUBMITTED)' [disabled]="!formGroup.valid"></app-submit-button>
        </div>
      </li>
    </ul>

    <ul class="btn-list" *ngIf="!isEdited">
      <li>
        <app-delete-button *ngIf="(canEdit || canDelete) && !isNewItem" (click)="delete()" [link]="true"></app-delete-button>
      </li>
      <li>
        <app-submit-button *ngIf="(event.status === EventStatus.DRAFT) || (event.status === EventStatus.PENDING)"
          (click)='submitEvent()' [disabled]="!formGroup.valid"></app-submit-button>
      </li>
      <li *ngIf="(canReject && event.status === EventStatus.SUBMITTED) || isRejected">
        <app-reject-button *ngIf="canReject && event.status === EventStatus.SUBMITTED" (click)="showRejectCommentPopup()"></app-reject-button>
        <app-reject-button *ngIf="isRejected" [style]="'reverse'"></app-reject-button>
      </li>
      <li *ngIf="(canApprove && (event.status === EventStatus.SUBMITTED || event.status === EventStatus.REJECTED)) || isApproved">
        <app-approve-button *ngIf="canApprove" (click)="showApproveCommentPopup()"></app-approve-button>
        <app-approve-button *ngIf="isApproved" [style]="'reverse'"></app-approve-button>
      </li>
    </ul>
  </div>
  <!-- END ACTIONS -->
</form>
