import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { SessionService } from '@services/session.service';
import { UserSettingsService } from '@services/user-settings.service';
import { Subject } from 'rxjs';
import { IntercomService } from '@services/intercom.service';
import { Preferences } from '@capacitor/preferences';
import { PushNotificationService } from '@services/notification/push-notification.service';
import { NGXLogger } from 'ngx-logger';
import { UrlGiverService } from '@services/url-giver.service';
import { HttpClient } from '@angular/common/http';
import { LocalNotifications, LocalNotificationPendingList } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  public signOutSignalObserver: Subject<void> = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private userSettingsService: UserSettingsService,
    private deviceService: DeviceService,
    private router: Router,
    private intercomService: IntercomService,
    private urlService: UrlGiverService,
    private http: HttpClient,
    private injector: Injector
  ) {
  }


  disconnectServices(): void {
    if (this.deviceService.isMobile && !this.deviceService.isMobileWeb) {
      this.deletePushTokenOnServer().then(() => {
        this.removeSession();
      }).catch(() => {
        this.removeSession();
      });
    } else {
      this.removeSession();
    }
  }

  public addLogoutCallback(callback: () => void): void {
    this.signOutSignalObserver.subscribe(() => {
      callback();
    });
  }

  private removeSession() {
    this.intercomService.shutdown();
    this.sessionService.removeSession();
    this.userSettingsService.clear();
    if (this.deviceService.isMobile) {
      this.cancelAllNotifications();
      this.signOutSignalObserver.next();
      this.router.navigate(['welcome']);
    } else {
      this.router.navigate(['login']).then(() => {
        this.signOutSignalObserver.next();
      });
    }
  }

  async cancelAllNotifications(): Promise<void> {
    const localNotificationsPending: LocalNotificationPendingList = await LocalNotifications.getPending();
    LocalNotifications.cancel(localNotificationsPending);
  }

  private deletePushTokenOnServer(): Promise<void> {
    const logger = this.injector.get(NGXLogger);
    return new Promise((resolve, reject) => {
      logger.info('Detected user logout. Attempting to delete push token.');
      const url = this.urlService.givePushNotificationAPIUrl();
      this.getPushTokenFromPreferences().then(token => {
        if (token) {
          this.http.delete(url, {
            params: {
              fcmToken: token
            }
          }).toPromise().then(() => {
            logger.info('Deleted push token on server successfully. Token: ' + token);
            this.deletePushTokenFromPreferences();
            resolve();
          });
        }
      }).catch(error => {
        logger.error('Unable to fetch push token from preferences for deleting on server: ', error);
        reject(error);
      });
    });
  }

  private async getPushTokenFromPreferences(): Promise<string> {
    return await Preferences.get({
      key: PushNotificationService.pushTokenStorageKey
    }).then((result) => result.value);
  }

  private async deletePushTokenFromPreferences() {
    const logger = this.injector.get(NGXLogger);
    return await Preferences.remove({
      key: PushNotificationService.pushTokenStorageKey
    }).then(() => {
      logger.info('Deleted Push Token successfully from preferences');
    }).catch(error => {
      logger.error('Unable to delete push token from preferences: ', error);
    });
  }
}
