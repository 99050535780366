import { HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of} from 'rxjs';
import {catchError, map, tap, flatMap, filter} from 'rxjs/operators';
import { Session } from '../models/session';
import { SiteUser } from '../models/site-user';
import { SpaceUser } from '../models/space-user';
import { Space } from '../models/space';
import { Site } from '../models/site';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';
import { DatabaseService } from './shared/database.service';
import { SessionService } from './session.service';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';
import { SharedService } from '@services/shared/shared.service';
import { HttpStatus } from '@constants/http/http-status';
import { NetworkStatus } from '@models/synchronization/network-status';
import { NGXLogger } from 'ngx-logger';
import { LogoutService } from './logout/logout.service';
import { SharedDataService } from './shared-data.service';
import { AppAccess, MultipleInviteUserRecord, MultipleInviteUserResponse, UserModel } from '@models/user-model';

export enum UserOperationErrorCodes {
  ONLY_ADMIN_IN_SPACE = 'ONLY_ADMIN_IN_SPACE'
}

@Injectable()
export class UserService {

  private userUrl: string;
  private userSpaceUrl: string;
  private userSiteUrl: string;
  private userInviteUrl: string;

  /**
   * Use to check if the last deleted request has work
   */
  deleted: Boolean;

  constructor(
    private sharedService: SharedService,
    private databaseService: DatabaseService,
    private https: HttpClient,
    private urlGiver: UrlGiverService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private authService: AuthService,
    private logger: NGXLogger,
    private logoutService: LogoutService,
    private sharedDataService: SharedDataService
  ) {
    this.userSpaceUrl = this.urlGiver.giveAPIUrl() + '/spaces';
    this.userUrl = this.urlGiver.giveAPIUrl() + '/profiles';
    this.userSiteUrl = this.urlGiver.giveAPIUrl() + '/tenant';
    this.userInviteUrl = this.urlGiver.giveUserInviteUrl();
  }

  /**
   * Convert json sent from backend to a {@link_Site} model for frontend
   * @param json Json sent from backend
   * @param siteUser Site to convert
   */
  public convertJsonToSiteUserModel(json: any, siteUser: SiteUser) {
    siteUser.id = json.id;
    siteUser.firstName = json.firstName;
    siteUser.lastName = json.lastName;
    siteUser.role = json.role;
    siteUser.mail = json.emailAddress;
    siteUser.fullName = json.firstName + ' ' + json.lastName;
  }

  /**
   * Convert json sent from backend to a {@link_Space} model for frontend
   * @param json Json sent from backend
   * @param spaceUser SpaceUser to convert
   */
  public convertJsonToSpaceUserModel(json: any, spaceUser: SpaceUser) {
    spaceUser.id = json.id;
    spaceUser.userId = json.userId;
    spaceUser.firstName = json.firstName;
    spaceUser.lastName = json.lastName;
    spaceUser.createdBy = json.createdBy;
    spaceUser.createdOn = json.createdOn;
    spaceUser.mail = json.emailAddress;
    spaceUser.lastAccess = new Date(json.lastAccess);
    spaceUser.role = json.role;
    spaceUser.isValidated = json.validated;
    spaceUser.siteDiaryAccess = json.siteDiaryAccess;
    spaceUser.siteTaskAccess = json.siteTaskAccess;
    spaceUser.active = json.active;
  }

  /**
   * Convert spaceUser object into spaceUserDto for backend
   * @param spaceUser SpaceUser to convert
   */
  public convertSpaceUserToSpaceUserDTO(spaceUser: SpaceUser) {
    const spaceUserDto = {};
    spaceUserDto['id'] = spaceUser.id;
    spaceUserDto['firstName'] = spaceUser.firstName;
    spaceUserDto['lastName'] = spaceUser.lastName;
    spaceUserDto['createdBy'] = spaceUser.createdBy;
    spaceUserDto['createdOn'] = spaceUser.createdOn;
    spaceUserDto['emailAddress'] = spaceUser.mail;
    spaceUserDto['lastAccess'] = new Date(spaceUser.lastAccess);
    spaceUserDto['role'] = spaceUser.role;
    spaceUserDto['validated'] = spaceUser.isValidated;
    spaceUserDto['siteDiaryAccess'] = spaceUser.siteDiaryAccess;
    spaceUserDto['siteTaskAccess'] = spaceUser.siteTaskAccess;
    spaceUserDto['active'] = spaceUser.active;
    return spaceUserDto;
  }

  /**
   * Convert siteUser object into siteUserDto for backend
   * @param site Site
   * @param spaceUser SpaceUser to convert
   */
  public convertUserToSiteUserDTO(site: Site, user: SiteUser) {
    const siteUserDto = {};
    siteUserDto['id'] = user.id;
    siteUserDto['role'] = user.role;
    siteUserDto['siteId'] = site.id;
    return siteUserDto;
  }

  public getSiteUsers(spaceId: string, siteId: string): Observable<SiteUser[]> {
    if (NetworkStatus.isOnline) {
      return this.getBackendSiteUsers(spaceId, siteId);
    } else {
      return this.getLocalSiteUsers(siteId);
    }
  }

  /*
   * GET users from backend
   */
  private getBackendSiteUsers(spaceId: string, siteId: string): Observable<SiteUser[]> {
    if (!spaceId || !siteId) {
      return of([]);
    }
    return this.https.get<SiteUser[]>(this.userSiteUrl + '/' + spaceId + '/sites/' + siteId + '/users')
      .pipe(
        map((response) => {
          const siteUsers: SiteUser[] = [];
          response.forEach(jsonSpace => {
            const siteUser: SiteUser = new SiteUser();
            this.convertJsonToSiteUserModel(jsonSpace, siteUser);
            siteUsers.push(siteUser);
          });
          this.sharedDataService.updateSiteUsers(siteUsers);
          return siteUsers;
        },
        catchError((error: HttpErrorResponse) => {
          this.logger.error('Error while fetching backend site users: ', error);
          return of([] as SiteUser[]);
        }))
      );
  }

  /**
  * Get local site users of given site
  * @param siteId Given site id
  */
  public getLocalSiteUsers(siteId): Observable<SiteUser[]> {
    return this.databaseService.watchDB().pipe(
      filter(db => db != null),
      flatMap(async db => {
        const users = await db.siteUser.where({ siteId: siteId }).toArray();
        return users;
      })
    );
  }

  /*
   * GET users from backend
   */
  getBackendSpaceUsers(spaceId: string): Observable<SpaceUser[]> {
    return this.https.get<SpaceUser[]>(this.userSpaceUrl + '/' + spaceId + '/emails')
      .pipe(
        map((response) => {
          const spaceUsers: SpaceUser[] = [];
          response.forEach(jsonSpace => {
            const spaceUser: SpaceUser = new SpaceUser();
            this.convertJsonToSpaceUserModel(jsonSpace, spaceUser);
            spaceUsers.push(spaceUser);
          });
          return spaceUsers;
        },
        catchError((error: HttpErrorResponse) => {
          this.logger.error('Error while fetching backend space users: ', error);
          return of([] as SpaceUser[]);
        }))
      );
  }

  inviteSpaceUser(spaceId: string, mail: string, siteDiaryAccess?: boolean, siteTaskAccess?: boolean, userRole?: string): Observable<SpaceUser> {
    let inviteUrl = this.userSpaceUrl + '/' + spaceId + '/emails?email=' + mail;
    if (siteDiaryAccess) {
      inviteUrl += '&siteDiaryAccess=true';
    } else {
      inviteUrl += '&siteDiaryAccess=false';
    }
    if (siteTaskAccess) {
      inviteUrl += '&siteTaskAccess=true';
    } else {
      inviteUrl += '&siteTaskAccess=false';
    }
    if (userRole) {
      inviteUrl += '&userRole=' + userRole;
    }
    return this.https.post<SpaceUser>(inviteUrl, null);
  }

  inviteMultipleSpaceUsers(users: UserModel[], spaceId: string): Promise<MultipleInviteUserResponse> {
    const inviteUrl = this.userSpaceUrl + '/' + spaceId + '/invite-users';
    const body: MultipleInviteUserRecord[] = [];
    users.forEach(user => {
      body.push({
        emailAddress: user.email,
        role: user.userRole,
        siteDiaryAccess: user.appAccess.includes(AppAccess.SITE_DIARY),
        siteTaskAccess: user.appAccess.includes(AppAccess.SITE_TASK)
      });
    });
    return this.https.post<MultipleInviteUserResponse>(inviteUrl, body).toPromise();
  }

  validateUserInvitation(key: string): Observable<{email: string}> {
    this.logger.info('TRY to confirm user invitation...');
    // Validate user invitation
    return this.https.get<{email: string}>(environment.apiUrl + '/accounts/invitation/' + key, {});
  }

  confirmUserEmail(key: string): Observable<{email: string}> {
    this.logger.info('TRY to confirm user email...');
    // Confirm email POST request
    return this.https.post<{email: string}>(environment.apiUrl + '/accounts/' + key, {});
  }

  addSiteUser(spaceId: string, site: Site, spaceUser: SpaceUser) {
    const siteUser = new SiteUser();
    siteUser.id = spaceUser.userId;
    siteUser.siteId = site.id;
    const siteUserDto = this.convertUserToSiteUserDTO(site, siteUser);
    return this.https.post(this.userSiteUrl + '/' + spaceId + '/sites/' + site.id + '/users',
      siteUserDto).toPromise();
  }

  deleteSpaceUser(spaceId: string, id: string) {
    return this.https.delete(this.userSpaceUrl + '/' + spaceId + '/emails/' + id);
  }

  deleteSiteUser(spaceId: string, siteId: string, userId: string) {
    return this.https.delete(this.userSiteUrl + '/' + spaceId + '/sites/' + siteId + '/users/' + userId);
  }

  updateSpaceUser(space: Space, spaceUser: SpaceUser) {
    const spaceUserDto = this.convertSpaceUserToSpaceUserDTO(spaceUser);
    return this.https.put(this.userSpaceUrl + '/' + space.id + '/emails/' + spaceUser.id, spaceUserDto);
  }

  updateSiteUser(spaceId: string, site: Site, siteUser: SiteUser) {
    const siteUserDto = this.convertUserToSiteUserDTO(site, siteUser);
    return this.https.put(this.userSiteUrl + '/' + spaceId + '/sites/' + site.id + '/users/' + siteUser.id, siteUserDto);
  }

  /**
   * Get an observable object of the target SiteUser
   * @param idUser Id of the target SiteUser
   */
  getUser(idUser: string): Observable<SiteUser> {
    return this.sharedService.watchSite.pipe(
      flatMap(site => this.getLocalSiteUsers(site.id)),
      map(siteUsers => siteUsers.find(siteUser => siteUser.id === idUser)),
    );
  }

  changeUserInfo(session: Session): Observable<any> {
    const url = `${this.userUrl}`;

    const userInfo: any = {};
    userInfo.firstName = session.user.firstName;
    userInfo.lastName = session.user.lastName;
    userInfo.phoneNumber = session.user.phoneNumber;

    return this.https.put(url, userInfo, this.urlGiver.httpOptions)
      .pipe(
        tap(data => {
          this.toasterService.showSuccessToaster('account.notify.new_information.success');
          this.sessionService.setSession(session);
        }),
        catchError(this.handleError('changedInformation'))
      );
  }

  changeUserPassword(newPassword: string, oldPassword: string): Observable<{}> {
    const url = `${this.userUrl}/password`;

    const userPassword: any = {};
    userPassword.newPassword = newPassword;
    userPassword.password = oldPassword;

    return this.https.put(url, userPassword, this.urlGiver.httpOptions)
      .pipe(
        tap((data: any) => {
          this.authService.updateSessionTokens(data.access_token, data.refresh_token);
          this.toasterService.showSuccessToaster('account.notify.new_password.success');
        }),
        catchError(this.handleError('changedPassword'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation Name of the operation that failed
   * @param result Optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    this.logger.error('Error while performing the following operation: ', operation);
    return (error: any): Observable<T> => {

      // Errors notifications

      // changedInformation error
      if (operation === 'changedInformation') {
        switch (Number(error.status)) {
          default:
            this.toasterService.showErrorToaster('account.notify.new_information.error');
            break;
        }
        // changedPassword error
      } else if (operation === 'changedPassword') {
        switch (Number(error.status)) {
          default:
            this.toasterService.showErrorToaster('account.notify.new_password.error');
            break;
        }
      } else {
        switch (Number(error.status)) {
          case HttpStatus.BAD_REQUEST:
          case HttpStatus.INTERNAL_SERVER_ERROR:
            this.toasterService.showErrorToaster('errors.ajax.error_global');
            break;
        }
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  deleteUserAccount() {
    this.logger.info('Attempting to delete the user account');
    const url = this.urlGiver.giveAPIUrl() + '/accounts';
    return this.https.delete(url, {observe: "response"});
  }

  clearDeletedUserData() {
    this.databaseService.clearDeletedUserData()
      .then(()=> {
        this.logoutService.disconnectServices();
      })
      .catch((error)=> {
        this.logger.error('Error encountered while deleting the data after deleting the user account. Logging the user out...', error);
        this.logoutService.disconnectServices();
      });
  }

  inviteUser(spaceId: string, mail: string, siteId?: string): Observable<SiteUser> {
    let params = new HttpParams()
      .set('email', mail);
    if(siteId) {
      params = params
      .set('siteId', siteId );
    }
    return this.https.post<SiteUser>(this.userInviteUrl + '/' + spaceId, null, {params: params});
  }
}
