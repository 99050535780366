/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CustomEventField, allLegacyFieldTypes, CustomEventLegacyFieldType } from '@models/custom-event-field';
import { CustomEventForm } from '@models/custom-event-form';
import { CustomEventFormSection } from '@models/custom-event-form-layout';
import { customFormLegacyFormLayout } from '@models/customFormLayout/custom-form-legacy-form-layout';
import { NetworkStatus } from '@models/synchronization/network-status';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AbstractModelService } from './abstract-model-service';
import { AppUpdateSettingsService } from './app-update-settings.service';
import { DeviceService } from './device.service';
import { SharedDataService } from './shared-data.service';
import { DatabaseService } from './shared/database.service';
import { SharedService } from './shared/shared.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';

export const CUSTOM_FORM_SUPPORTED_VERSION = 1;
export enum CustomFormSupportDialogType {
  EVENT_FORM,
  EVENT_PREVIEW,
  EVENT_EDIT
}

@Injectable({
  providedIn: 'root'
})

export class CustomEventFormService extends AbstractModelService<CustomEventForm> {

  protected type = 'CustomEventForm';
  private tenantUrl: string;
  private headers: HttpHeaders;

  private allLegacyFields = JSON.parse(JSON.stringify(allLegacyFieldTypes)) as CustomEventLegacyFieldType[];

  private customFormLayout$ = new BehaviorSubject<CustomEventFormSection[]>([]);
  private customFormFields$ = new BehaviorSubject<CustomEventField[]>([]);
  private customFormLegacyFields$ = new BehaviorSubject<CustomEventLegacyFieldType[]>(this.allLegacyFields);
  public customFormLayout = this.customFormLayout$.asObservable();
  public customFormFields = this.customFormFields$.asObservable();
  public customFormLegacyFields = this.customFormLegacyFields$.asObservable();
  currentSpaceId: string;
  isSitePresentInSpace: boolean = true;

  private isUpdateAlertShown: boolean = false;

  constructor(
    protected databaseService: DatabaseService,
    private logger: NGXLogger,
    private http: HttpClient,
    private urlGiverService: UrlGiverService,
    private sharedService: SharedService,
    private toasterService: ToasterService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService,
    private alertController: AlertController,
    private translationService: TranslateService,
    private appUpdateSettings: AppUpdateSettingsService,
  ) {
    super(
      databaseService,
    );
    this.tenantUrl = urlGiverService.giveTenantAPIUrl();
    this.headers = this.urlGiverService.httpOptions.headers;
    if (this.deviceService.isMobile) {
      this.sharedService.watchSpaceId.subscribe(spaceId => {
        this.currentSpaceId = spaceId;
      });
    } else {
      this.sharedDataService.watchSpaceId.subscribe(spaceId => {
        this.currentSpaceId = spaceId;
      });
    }
  }

  updateCustomFormLayout(newLayout: CustomEventFormSection[]): void {
    this.customFormLayout$.next(newLayout);
  }

  updateCustomFormFields(newFields: CustomEventField[]): void {
    this.customFormFields$.next(newFields);
  }

  updateCustomFormLegacyFields(legacyFields: CustomEventLegacyFieldType[]): void {
    this.customFormLegacyFields$.next(legacyFields);
  }

  public async updateForm(currentForm: CustomEventForm): Promise<boolean> {
    return this.postNewForm(currentForm);
  }

  private async postNewForm(form: CustomEventForm): Promise<boolean> {
    const formCopy = form;
    const payload = formCopy.toDTO();
    const formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    if (NetworkStatus.isOnline) {
      return this.http.post(formUrl, payload, {headers: this.headers}).toPromise()
        .then(async (response) => {
          this.logger.debug('Created new custom form successfully.', response);
          if (this.deviceService.isMobile) {
            await this.setOtherFormsToInactive(formCopy.id);
          }
          return true;
        }).catch((error: HttpErrorResponse) => {
          this.logger.error('Error encountered while saving the custom form with the server.', error);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.error.status === 400 && error.error.message === 'Duplicate form name') {
            throw new Error('Duplicate form name');
          }
          return false;
        });
    } else {
      this.toasterService.showWarningToaster('forbidden-offline');
      return false;
    }

  }

  async resetToDefaultForm(): Promise<boolean> {
    const formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    if (NetworkStatus.isOnline) {
      return this.http.put(formUrl, null, {headers: this.headers}).toPromise()
        .then((response) => {
          this.logger.debug('Reset to default form successfully.', response);
          return true;
        }).catch((error) => {
          this.logger.error('Error encountered while trying to reset to the default form with the server.', error);
          return false;
        });
    } else {
      this.toasterService.showWarningToaster('forbidden-offline');
      return false;
    }
  }

  async getCurrentActiveFormFromDB(siteId?: string): Promise<CustomEventForm | null> {
    try {
      const db = await this.databaseService.getDBInstant();
      const allForms = await db.customEventForm.toArray();
      let activeForm = allForms.filter((form) => form.isSelected === true);
      if (siteId) {
        activeForm = activeForm.filter((form) => form.siteId === siteId);
      }
      if (activeForm[0]) {
        return activeForm[0];
      } else {
        return null;
      }
    } catch (error) {
      this.logger.error('Error while fetching active custom event form from DB.', error);
      return null;
    }
  }

  async getFormFromBackend(): Promise<CustomEventForm | null> {
    const formUrl: string = this.urlGiverService.giveCustomFormAPIUrl(this.currentSpaceId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newActiveForm: CustomEventForm | null =  await this.http.get(formUrl, {headers: this.headers}).toPromise()
      .then((response) => {
        const customForm = new CustomEventForm();
        CustomEventForm.convertSpaceFormDTOToModel(response, customForm);
        return customForm;
      }).catch((error: HttpErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.error?.errorCode === 'NO_SITE_IN_SPACE'){
          this.isSitePresentInSpace = false;
        }
        this.logger.error('Error while fetching latest active form from server.', error);
        return null;
      });
    return newActiveForm;
  }

  async getCurrentEventCustomFormFromDB(formId: string): Promise<CustomEventForm | null> {
    try {
      if (formId) {
        const db = await this.databaseService.getDBInstant();
        const activeForm = await db.customEventForm.where('id').equals(formId).toArray();
        if (activeForm[0]) {
          return activeForm[0];
        } else {
          return null;
        }
      } else {
        this.logger.error('Event does not have form layout ID.');
        return null;
      }
    } catch (error) {
      this.logger.error('Error while fetching current event custom form from DB.', error);
      return null;
    }
  }

  async getLegacyFormLayout(): Promise<CustomEventForm> {
    const customLegacyForm = new CustomEventForm();
    CustomEventForm.toModel(customFormLegacyFormLayout, customLegacyForm);
    return customLegacyForm;
  }

  async getAllFormTitlesFromDB(): Promise<string[]> {
    const allFormTitles: string[] = [];
    const db = await this.databaseService.getDBInstant();
    const allForms = await db.customEventForm.toArray();
    allForms.forEach((form) => {
      allFormTitles.push(form.title);
    });
    return allFormTitles;
  }

  /**
   * Set all other forms except for the forms with the matching formId to inactive.
   */
  async setOtherFormsToInactive(formId: string) {
    const db = await this.databaseService.getDBInstant();
    await db.customEventForm.toCollection().modify(form => {
      if (form.id !== formId) {
        form.isSelected = false;
      }
    });
  }

  isCustomFormUpdateRequired(customForm: CustomEventForm): boolean {
    if (customForm.formVersion > CUSTOM_FORM_SUPPORTED_VERSION) {
      return true;
    }
    return false;
  }

  getSupportDialogHeader(type: CustomFormSupportDialogType): string {
    switch(type) {
      case CustomFormSupportDialogType.EVENT_FORM:
        return 'custom_form_warning_update_title';
      case CustomFormSupportDialogType.EVENT_PREVIEW: 
      case CustomFormSupportDialogType.EVENT_EDIT:
        return 'custom_form_warning_event_title';
      default:
        this.logger.error(`Unknown CustomFormSupportDialogType: ${type}`);
        return 'custom_form_warning_update_title';;
    }
  }

  getSupportDialogMessage(type: CustomFormSupportDialogType): string {
    switch(type) {
      case CustomFormSupportDialogType.EVENT_FORM:
        return 'custom_form_warning_update_message';
      case CustomFormSupportDialogType.EVENT_PREVIEW: 
        return 'custom_form_warning_event_message';
      case CustomFormSupportDialogType.EVENT_EDIT:
        return 'custom_form_warning_event_edit_message';
      default:
        this.logger.error(`Unknown CustomFormSupportDialogType: ${type}`);
        return 'custom_form_warning_update_message';;
    }
  }

  updateAction() {
    if (this.deviceService.isMobile) {
      this.appUpdateSettings.goToAppStore();
    } else {
      window.location.reload();
    }
  }
}
